// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

$modal-size: 400px;
$submit-row-gap: 15%;

.report-table-issue-modal {
  background-color: $white;
  border-radius: $popover-border-radius;
  bottom: 100px;
  box-shadow: 0 0 24px -2px rgba(0, 0, 0, 0.2);
  display: block;
  height: auto;
  left: 25px;
  min-height: $modal-size;
  padding: $spacer-4;
  position: fixed;
  width: $modal-size;
  z-index: 99;

  .btn.btn-close {
    position: absolute;
    right: $spacer-4;
    top: $spacer-4;
  }

  .data-owner-notification {
    font-size: 10px;
    margin: 5px 0 0;
  }

  .submit-row {
    display: flex;
    gap: $submit-row-gap;
    justify-content: space-between;
  }
}
