// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';

.flag {
  border-radius: 5px;
  display: inline-block;
  font-size: $font-size-base;
  height: $badge-height;
  margin: 0 $spacer-1 0 0;
}

.label {
  border-radius: 10px;
  font-weight: normal;
}

.actionable-badge {
  &:hover {
    cursor: pointer;
  }

  .label {
    padding: 0 0;
  }

  [class^='badge-overlay-'] {
    font-size: $font-size-base;
    text-align: center;
    padding: 0.2em 0.6em 0.3em;

    &:hover,
    &:focus {
      color: $badge-text-color;
      height: $badge-height;
      width: 100%;
      border-radius: $badge-height;
    }

    &:focus {
      // TODO verify if this is what it is supposed to look like
      // more round?

      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  .badge-overlay-negative,
  .badge-overlay-neutral,
  .badge-overlay-positive,
  .badge-overlay-primary {
    &:hover,
    &:focus {
      background-color: rgba(
        $color: $badge-overlay,
        $alpha: $badge-opacity-light
      );
    }

    &:active {
      background-color: rgba(
        $color: $badge-overlay,
        $alpha: $badge-pressed-light
      );
    }
  }

  .badge-overlay-warning {
    &:hover,
    &:focus {
      background-color: rgba(
        $color: $badge-overlay,
        $alpha: $badge-opacity-dark
      );
    }

    &:active {
      background-color: rgba(
        $color: $badge-overlay,
        $alpha: $badge-pressed-dark
      );
    }
  }
}
