// Copyright Contributors to the Amundsen project.
// SPDX-License-Identifier: Apache-2.0

@import 'variables';
@import 'typography';

$alert-border-radius: 4px;
$alert-warning-background: #fff0d4;
$alert-alert-background: #ffe4dd;

.alert-list {
  margin-top: $spacer-3;
  margin-bottom: 0;

  .alert {
    margin-bottom: $spacer-1;
  }
}

.alert {
  border-radius: $alert-border-radius;
  display: flex;
  padding: $spacer-1 $spacer-1 * 1.5 $spacer-1 $spacer-2;
  justify-content: flex-start;
  box-shadow: $elevation-level2;
  border: none;

  &.is-info {
    background-color: $body-bg;
  }

  &.is-warning {
    background-color: $alert-warning-background;
  }

  &.is-alert {
    background-color: $alert-alert-background;
  }

  .alert-message {
    @extend %text-body-w2;

    margin: auto auto auto 0;
    display: inline;
  }

  .alert-triangle-svg-icon,
  .info-svg-icon {
    flex-shrink: 0;
    align-self: center;
    margin-right: $spacer-1;
  }

  .info-svg-icon {
    margin-right: $spacer-half;
    margin-left: -$spacer-half;
  }

  .alert-action {
    margin: auto 0 auto auto;
  }
}
